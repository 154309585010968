<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <v-img
                    :src="require('../assets/Lafontylogo.png')"
                    class="my-3"
                    contain
                    height="128"
                />
            </v-col>

            <v-col class="mb-4">
                <h1 class="display-2 font-weight-bold mb-3">
                    欢迎访问“鼎钧家居装修施工团队”网站
                </h1>

                <p class="subheading font-weight-regular">
                    我们不是设计师团队，只是专注于家居装修施工的团队
                    <br />请访问我们的在线信息
                    <a href="#" target="_blank"
                        >团队信息</a
                    >
                </p>
            </v-col>

            <v-col class="mb-5" cols="12">
                <h2 class="headline font-weight-bold mb-3">我们都能做什么?</h2>

                <v-row justify="center">
                    <a
                        v-for="(next, i) in whatsNext"
                        :key="i"
                        :href="next.href"
                        class="subheading mx-3"
                        target="_blank"
                    >
                        {{ next.text }}
                    </a>
                </v-row>
            </v-col>

            <v-col class="mb-5" cols="12">
                <h2 class="headline font-weight-bold mb-3">您为什么选我们做施工项目？</h2>

                <v-row justify="center">
                    <a
                        v-for="(link, i) in importantLinks"
                        :key="i"
                        :href="link.href"
                        class="subheading mx-3"
                        target="_blank"
                    >
                        {{ link.text }}
                    </a>
                </v-row>
            </v-col>

            <v-col class="mb-5" cols="12">
                <h2 class="headline font-weight-bold mb-3">您可以做些什么？</h2>

                <v-row justify="center">
                    <a
                        v-for="(eco, i) in ecosystem"
                        :key="i"
                        :href="eco.href"
                        class="subheading mx-3"
                        target="_blank"
                    >
                        {{ eco.text }}
                    </a>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "HelloWorld",

    data: () => ({
        ecosystem: [
            {
                text: "参与设计落地方案",
                href: "#",
            },
            {
                text: "DIY局部项目施工",
                href: "#",
            },
            {
                text: "写博客、发朋友圈",
                href: "#",
            },
        ],
        importantLinks: [
            {
                text: "五年全面保修",
                href: "#",
            },
            {
                text: "只专注施工，我们不设计",
                href: "#",
            },
            {
                text: "详细、准确的施工图",
                href: "#",
            },
            {
                text: "坚实的全屋定制基础",
                href: "#",
            },
            {
                text: "倒序项目推导流程",
                href: "#",
            },
        ],
        whatsNext: [
            {
                text: "毛坯房装修施工",
                href: "#",
            },
            {
                text: "精装修微改造施工",
                href: "#",
            },
            {
                text: "旧房改造装修施工",
                href: "#",
            },
        ],
    }),
};
</script>
