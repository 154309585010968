<template>
    <v-app>
        <v-app-bar app color="primary" dark>
            <div class="d-flex align-center">
                <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    src="./assets/Lafontylogo.png"
                    transition="scale-transition"
                    width="40"
                />

                <div>
                    <h2>鼎钧家居施工团队</h2>
                </div>
            </div>

            <v-spacer></v-spacer>

            <v-btn href="#" target="_blank" text>
                <span class="mr-2">联系我们</span>
                <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <HelloWorld />
        </v-main>
        <v-footer color="#333333">
            <v-container class="text-center">
                <div>
                    <p style="color: white">
                        备案号：苏ICP备
                        <a :href="link" class="linkA">{{ msg }}</a>
                    </p>
                </div>
            </v-container>
        </v-footer>
    </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";

export default {
    name: "App",

    components: {
        HelloWorld,
    },

    data: () => ({
        msg: "2021045225号-1",
        link: "https://beian.miit.gov.cn/",
    }),
};
</script>

<style scoped>
.linkA {
    text-decoration: none;
    font-size: 1rem;
    color: #cecece;
}

.linkA:hover {
    color: #0088a9;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}
</style>